import { useEffect } from "react";
import { LeadType } from "../Components/PrivateRoute/types";
import Leads from "../Sections/AgencyListing/Leads";
function WarmLead() {

  return (
    <div>
      <Leads leadType={LeadType.WARM} />
    </div>
  );
}

export default WarmLead;
