import React, { createContext, useContext, useState, FC, ReactNode } from 'react';

interface PaginationState {
  currentPage: number;
  itemsPerPage: number;
}

interface PaginationContextType {
  paginationState: Record<string, PaginationState>;
  setPaginationState: (key: string, state: PaginationState) => void;
}

const PaginationContext = createContext<PaginationContextType | undefined>(undefined);

interface PaginationProviderProps {
  children: ReactNode;
}

export const PaginationProvider: FC<PaginationProviderProps> = ({ children }) => {
  const [paginationState, setPaginationStateRaw] = useState<Record<string, PaginationState>>({});

  const setPaginationState = (key: string, state: PaginationState) => {
    setPaginationStateRaw(prevState => ({ ...prevState, [key]: state }));
  };

  const value = {
    paginationState,
    setPaginationState,
  };

  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  );
};
export const usePagination = (key: string) => {
  const context = useContext(PaginationContext);
  if (!context) {
    throw new Error('usePagination must be used within a PaginationProvider');
  }

  const { paginationState, setPaginationState } = context;

  const state = paginationState[key] || { currentPage: 1, itemsPerPage: 5 };

  const setCurrentPage = (currentPage: number) => {
    setPaginationState(key, { ...state, currentPage });
  };

  const setItemPerPage = (itemsPerPage: number) => {
    setPaginationState(key, { ...state, itemsPerPage });
  };

  return { ...state, setCurrentPage, setItemPerPage };
};
