import AWS from "aws-sdk";
const S3_BUCKET_NAME = "agencyreviews-dev";
const REGION = "us-east-1";
const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_SECRET;

export async function handleFileUploadCommon(file) {
  if (
    file &&
    !["image/svg+xml", "image/png", "image/jpeg", "application/pdf"].includes(
      file.type
    )
  ) {
    console.log("Only SVG, PNG, and JPG files are allowed.");
    return { Location: "" };
  } else {
    try {
      AWS.config.update({
        accessKeyId: ACCESS_KEY,
        secretAccessKey: SECRET_ACCESS_KEY,
        region: REGION,
      });
      const s3 = new AWS.S3();
      console.log("Key: " + file?.name);
      const params: AWS.S3.PutObjectRequest = {
        Bucket: S3_BUCKET_NAME,
        // selectedFile?.selectedFile
        Key: file.name,
        ContentType: file.type,
        Body: file,
        // ACL: 'public-read', // If you want the uploaded image to be publicly accessible
      };

      const uploadPromise = s3.upload(params).promise();
      const data = await uploadPromise;
      return data;
    } catch (error) {
      console.error("Error uploading image:", error);
      return { Location: "" };
    }
  }
}

export async function uploadObjectToS3(signedUrl, file) {
  try {
    const response = await fetch(signedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type, // Assuming file is a Blob or File object
      },
    });

    if (response.ok) {
      console.log("Upload successful", response);
    } else {
      console.error("Upload failed:", response.statusText);
    }
  } catch (error) {
    console.error("Upload failed:", error);
  }
}

export async function fetchSitemapFromS3() {
  let key = "";
  const baseUrl = window.location.href;

  if (baseUrl.includes("staging")) {
    key = `exports/staging_sitemap.xml`;
  } else if (baseUrl.includes("dev")) {
    key = `exports/dev_sitemap.xml`;
  } else if (baseUrl.includes("localhost")) {
    key = `exports/dev_sitemap.xml`;
  } else {
    key = `exports/prod_sitemap.xml`;
  }

  const params = {
    Bucket: S3_BUCKET_NAME,
    Key: key, // The key of the sitemap file in S3
  };
  try {
    AWS.config.update({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY,
      region: REGION,
    });
    const s3 = new AWS.S3();
    const data = await s3.getObject(params).promise();
    const sitemapContent = data.Body?.toString("utf-8"); // Convert the Buffer to a string
    return sitemapContent;
  } catch (error) {
    console.error("Error fetching sitemap from S3:", error);
    return null;
  }
}
