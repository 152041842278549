import React, { useState } from 'react';

export const BioComponent = ({ htmlContent, maxDescriptionText = 100, listView=false }) => {
  const [seeMore, setSeeMore] = useState(false);
  const isTabMobile = window.innerWidth < 1260;
  
  const isDetailPage= window.location.pathname.includes('AgencyDetails')

  const handleSeeMoreClick = (e) => {
    e.preventDefault(); // Prevent default action if needed
    e.stopPropagation(); // Stop the event from propagating to parent elements
    setSeeMore(!seeMore);
  };

  let displayContent  
  if(listView){
    displayContent =listView && seeMore ? htmlContent : `${htmlContent.substring(0, maxDescriptionText)}...`;
  }
  else {
    displayContent = isTabMobile ? seeMore ? htmlContent : `${htmlContent.substring(0, maxDescriptionText)}...`: htmlContent;
  }
  return (
    <div className="text-[16px] font-medium font-montserrat leading-[24px] mt-5 text-[#344054] bio-content">
      <div dangerouslySetInnerHTML={{ __html: displayContent }} />
      <div style={{ minWidth: !isTabMobile ? '47rem' :'' }}>
      {htmlContent.length > maxDescriptionText && (
        // <button
        //   className="ml-1 font-bold"
        //   style={{
        //     color: '#3364F7',
        //     fontWeight: '600',
        //     cursor: 'pointer',
        //   }}
        //   onClick={handleSeeMoreClick}
        // >
        //   {seeMore ? 'See less' : 'See more'}
        // </button>
        <div className="button-container">

			<button
				className="collapse-button text-[14px] font-semibold font-montseorrat text-[#329BFA] cursor-pointer ml-auto mt-2"
				onClick={handleSeeMoreClick}
			>
		 {seeMore ? 'See less' : 'See more'}
			</button>
		</div>
      )}
        </div>

    </div>
  );
};
