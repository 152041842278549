import { gql, useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import ToastContext from '../Context/ToastContext';
import Loader from '../Components/Loader';

export default function Calendly() {

    const GET_AGENCY = gql`
	query GetAgencyWithAnalytics($id: Float!) {
		getAgency(id: $id) {
			agency {
				id
				name
	            calendarLink
                showCalendarLink
				email
			}
		}
	}`;


    const UPDATE_AGENCY = gql`
        mutation UpdateAgency($id: Float!, $data: UpdateAgencyInput!) {
        updateAgency(id: $id, data: $data) {
           calendarLink
           showCalendarLink
        }
        }`;

    const [submitLoading, setSubmitLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [calendlyLink, setCalendlyLink] = useState('');
    const [userAgency, setUserAgency] = useState<any>(null);
    const { showToast, hideToast } = useContext(ToastContext);
    const [calendlyError, setCalendlyError] = useState<any>(false);
    const [updateAgency, { data }] = useMutation(UPDATE_AGENCY);
    const [getAgencyDetails, { data: agencyData }] = useLazyQuery(GET_AGENCY, {
        variables: { id: parseFloat(userAgency?.id) },
    });
    // Handler to update state when an option is selected
    const [isToggled, setIsToggled] = useState(false);

    const toggle = () => {
        setIsToggled(!isToggled);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };


    const getAgency = async () => {
        if (userAgency) {
            await getAgencyDetails();
        }
    }
    const userAgencyData = localStorage.getItem('user_agency');
    const websiteRegex = /^(https?:\/\/)?((localhost|[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|(\d{1,3}\.){3}\d{1,3})(:\d+)?(\/[a-zA-Z0-9-._~:\/?#[\]@!$&'()*+,;=]*)?$/;
    // const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*([a-zA-Z0-9-]+)(:\d+)?(\/[a-zA-Z0-9-._~:\/?#[\]@!$&'()*+,;=]*)?$/;

    useEffect(() => {
        if (
            userAgencyData !== undefined &&
            userAgencyData !== 'undefined' &&
            JSON.parse(`${userAgencyData}`)
        ) {
            let agency = JSON.parse(`${userAgencyData}`);
            setUserAgency(agency?.agency || null);
        }
    }, [userAgencyData]);


    useEffect(() => {
        if (userAgency) {
            getAgency();
        }
    }, [userAgency]);

    useEffect(() => {
        if (agencyData) {
            if (agencyData?.getAgency?.agency?.calendarLink) {
                setCalendlyLink(agencyData?.getAgency?.agency?.calendarLink);
            }
            setIsToggled(agencyData?.getAgency?.agency?.showCalendarLink);
            setDataLoading(false);
        }
    }, [agencyData]);

    const updateCalendyLink = async () => {
        if (calendlyLink == '') {
            setCalendlyError(true);
            return;
        } else if (!websiteRegex.test(calendlyLink)) {
            setCalendlyError(true);
            return;
        } else {
            setCalendlyError(false);
        }
        const agencyId = userAgency?.id;
        setSubmitLoading(true);
        const { data: agencyData } = await updateAgency({
            variables: {
                id: parseFloat(agencyId),
                data: {
                    calendarLink: calendlyLink,
                    showCalendarLink: isToggled
                },
            },
        });

        setSubmitLoading(false);
        showToast('Calendly Link updated successfully!', 'success');
        setTimeout(() => {
            hideToast();
        }, 3000);
    };

    if (dataLoading) {
        return (
            <div className='mt-40' >
                <Loader stroke='#329BFA' />
            </div>
        )
    }

    return (
        <div className='p-[12px]' style={{ width: '100%' }}>
            <p
                style={{ fontFamily: 'Montserrat' }}
                className='font-semibold text-[24px] leading-[30px] text-[#344054]'
            >
                Calendly
            </p>
            <form onSubmit={handleSubmit} className='flex flex-col gap-4 my-[32px]'>
                <div className='flex flex-col gap-[4px]'>
                    <p
                        style={{ fontFamily: 'Montserrat' }}
                        className='text-[14px] leading-[20px]  text-[#344054]'
                    >
                        Calendly Link
                        {/* <span className='text-[#D92D20]'>*</span> */}
                    </p>
                    <div
                        className='w-[100%] relative mt-2'
                        style={{ border: calendlyError ? '1px solid red' : '', borderRadius: '8px' }}
                    >
                        <input
                            onChange={(e) => {
                                setCalendlyLink(e.target.value);
                                if (websiteRegex.test(e.target.value)) {
                                    setCalendlyError(false);
                                }
                            }}
                            value={calendlyLink}
                            style={{ fontFamily: 'Montserrat' }}
                            className='w-[100%] h-[40px]  px-[14px] py-[10px]  rounded-[8px] text-[#667085] text-[14px] leading-[20px]'
                            type='text'
                            placeholder='Please enter calendly link here ...'
                        />
                    </div>
                    {calendlyError && <p className=" text-sm" style={{ color: "#F04438" }}>
                        {
                            'Please enter valid link'
                        }
                    </p>}
                </div>
            </form>
            <div className='flex items-center gap-2 mt-4'>
                <div className="flex items-center justify-center">
                    <button
                        onClick={toggle}
                        className={`w-16 h-8 flex items-center rounded-full p-1 ${isToggled ? 'bg-[#329BFA]' : 'bg-[#F2F4F7]'
                            }`}
                    >
                        <div
                            className={`bg-[white] w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out ${isToggled ? 'translate-x-8' : ''
                                }`}
                        />
                    </button>
                </div>
                <p
                    style={{ fontFamily: 'Montserrat' }}
                    className='text-[14px] leading-[20px] text-[#344054]'
                >
                    Show on agency listing page
                </p>
            </div>
            <div className='flex gap-[12px] flex-wrap md:justify-end justify-center'>
                <button onClick={() => {
                    setCalendlyLink(agencyData?.getAgency?.agency?.calendarLink ?? '');
                    setIsToggled(agencyData?.getAgency?.agency?.showCalendarLink ?? '');
                }} className=" w-[194px] h-[44px] spy-[10px] px-[18px] font-semibold leading-[24px] text-[#344054] rounded-[8px] border border-[#D0D5DD]">
                    Cancel
                </button>
                <button
                    className=' w-[194px] bg-[#329BFA] h-[44px] spy-[10px] px-[18px] font-semibold leading-[24px] text-[#FFF] rounded-[8px] '
                    disabled={submitLoading}
                    onClick={() => {
                        updateCalendyLink();
                    }}
                >
                    {
                        !submitLoading ? 'Save ' :
                            <Loader />
                    }
                </button>
            </div>
        </div>
    );
}
