import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { LoadScript } from "@react-google-maps/api";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { PaginationProvider } from "./Context/PaginationContext";
import { SpinnerProvider } from "./Context/SpinnerContext";
import { ToastProvider } from "./Context/ToastContext";
import { UserProvider } from "./Context/UserContext";
import "./index.css";
import { MiscProvider } from "./Context/MiscContext";
import TagManager from 'react-gtm-module';
import { GoogleAnalyticsProvider } from "./Context/GoogleAnalyticsContext";
import { HelmetProvider } from 'react-helmet-async';
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const client = new ApolloClient({
  uri:
    process.env.REACT_APP_BASE_URL ||
    "https://agencyreviews-api-staging.powerhouse.so/graphql",
  // 'http://localhost:3001/graphql',
  cache: new InMemoryCache(),
});


const tagManagerArgs = {
  gtmId: 'GTM-WLSTG9DC'
}

TagManager.initialize(tagManagerArgs)

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";
const domain = process.env.REACT_APP_DOMAIN || "";
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";


root.render(
  <React.StrictMode>
    <HelmetProvider>
      <GoogleAnalyticsProvider>
        <PaginationProvider>
          {/* <MiscProvider> */}
          <SpinnerProvider>
            <ToastProvider>
              <UserProvider>
                <Auth0Provider
                  domain={domain}
                  clientId={clientId}
                  authorizationParams={{
                    redirect_uri: window.location.origin,
                  }}
                >
                  <ApolloProvider client={client}>
                    <LoadScript
                      libraries={["places"]}
                      googleMapsApiKey={googleMapsApiKey}
                    >
                      <App />
                    </LoadScript>
                  </ApolloProvider>
                </Auth0Provider>
              </UserProvider>
            </ToastProvider>
          </SpinnerProvider>

          {/* </MiscProvider> */}
        </PaginationProvider>
      </GoogleAnalyticsProvider>
    </HelmetProvider>
  </React.StrictMode>
);