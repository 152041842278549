import React, { useMemo, useState } from 'react';
import './slider.css'; // make sure to create a CSS file with the name 'Slider.css'

const Slider = ({ values, userQuizSelectedData={}, setUserQuizSelectedData }) => {
  // Assuming 'values' is an array of objects with 'value' property like: [{ buttonText: '...', value: 1000 }, ...]
  const [index, setIndex] = useState(0); // Start with the first index
  const [sliderPercentage, setSliderPercentage] = useState(0); // Start with 0% filled

  const [isMobile] = useState(() => {
    if (window.screen.width <= 480) {
      return true;
    }
    return false;
  })

  const handleChange = (event) => {
    const newIndex = Number(event.target.value);
    const newPercentage = (newIndex / (values.length - 1)) * 100;
    setIndex(newIndex);
    setSliderPercentage(newPercentage); // Update the slider percentage in the state
    const budgets = ["Under $3k", "Under $10k" ,"Under $30k","Under $60k", "Over $60k"]
    if(setUserQuizSelectedData){
      setUserQuizSelectedData((prevValue)=>({
          ...prevValue,
          budget:  budgets[newIndex]
      }))
     }
  };

  // To position the tooltip, calculate the percentage based on the index
  const leftStyle = useMemo(() => {

    let left = (index / (values.length - 1)) * 100;
    if (left < 15) {
      left = 15;
    } else if (left > 85) {
      left = 85;
    }
    return `${left}%`;
  }, [index, values.length])

  console.log({ leftStyle });
  const sliderValue = values[index].value === 1 ? "Under $3k" : values[index].value === 2 ? "Under $10k" : values[index].value === 3 ? "Under $30k" : values[index].value === 4 ? "Under $60k" : "Over $60k"


  return (
    <div className="slider-container">
      <div className='text-center font-montserrat font-semibold text-2xl text-gray-900 mb-20'>What’s your monthly agency budget?</div>
      <input
        type="range"
        min="0"
        max={values.length - 1} // The max value is the length of the array minus 1
        value={index} // The value is the current index
        onChange={handleChange}
        className="slider"
        step="1" // This makes the slider snap to the nearest index
        style={{ background: `linear-gradient(to right, #3364F7 ${sliderPercentage}%, #EAECF0 ${sliderPercentage}%)` }}
      />
      <div className="slider-value" style={{ left: leftStyle, width: '134px' }}>
        <span>{sliderValue}</span>
      </div>
    </div>
  );
};

export default Slider;
