import { useContext, useEffect, useRef, useState } from "react";
import "./getmatchedmodal.css";
import { Modal } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { ReactComponent as CrossBtnIcon } from "../../assets/Icons/x-btn.svg";
import "./getmatchedmodal.css";
import circle from "../../assets/Icons/step-circle.png";
import circlelight from "../../assets/Icons/step-circle-light.png";
import check from "../../assets/Icons/step-check.png";
import {
  CostData,
  ExpertiesData,
  ServicesData,
} from "../../Utilities/utilities";
import PerfectMatchModal from "../Modal/PerfectMatchModal/Modal";
import { BeatLoader } from "react-spinners";
import { ReactComponent as ModalHeader } from "../../assets/images/modalHeader.svg";
import { ReactComponent as MobileModalHeader } from "../../assets/images/mobileModal.svg";

import Slider from "./Slider";
import Search from "../../assets/Gifs/loading.gif";
import MatchesModal from "./MatchesModal";
import { UserContext } from "../../Context/UserContext";
import { Link, useLocation } from "react-router-dom";
import BellIcon from "../../assets/Icons/bell.svg";
import { isUserLogin } from "../../Utilities/LoginValidator";
import { useMixpanel } from "react-mixpanel-browser";
interface ModalProps {
  isOpen?: any;
  onClose?: any;
  onSubmitQuizz?: any;
  loading?: any;
  isMatchFound?: any;
  matchCount?: any;
  onSubmitDetails?;
  setIsOpenGetMatchedModal?: any;
  setIsOpenCross?: any;
}

interface FormState {
  email: string;
  website: string;
  description: string;
}

interface UtmParams {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
}

const GetMatched = ({
  isOpen,
  onClose,
  onSubmitQuizz,
  loading,
  isMatchFound,
  matchCount,
  onSubmitDetails,
  setIsOpenGetMatchedModal,
  setIsOpenCross,
}: ModalProps) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const websiteRegex =
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}([/?].*)?$/;

  const mixpanel = useMixpanel();
  const location = useLocation();

  const getUrlParams = (url: string): { utm_source: string | null; utm_medium: string | null; utm_campaign: string | null } => {
    const urlParams = new URLSearchParams(new URL(url).search);
    return {
      utm_source: urlParams.get('utm_source'),
      utm_medium: urlParams.get('utm_medium'),
      utm_campaign: urlParams.get('utm_campaign'),
    };
  };

  const [utmParams, setUtmParams] = useState<UtmParams>({
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
  });

  useEffect(() => {
    // Assume the URL is window.location.href
    const currentUrl = window.location.href;
    const params = getUrlParams(currentUrl);
    setUtmParams(params);
  }, []);

  const steps = ["Service", "Expertise", "Budget", "Last step"];
  const [activeStep, setActiveStep] = useState(0);
  const [loadingModal, setLoadingModal] = useState(false);
  const { token, user, updateSession } = useContext(UserContext);
  const [dropdown, setDropdown] = useState(false);
  const [userQuizSelectedData, setUserQuizSelectedData] = useState({
    services: null,
    industry: null,
    budget: null,
    email: "",
    website: "",
    description: "",
  });
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const elementRef = useRef<HTMLDivElement>(null);
  const [validationErrors, setValidationErrors] = useState<Partial<FormState>>(
    {}
  );
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );

  const data = [
    {
      description: "What do you need help with the most?",
      tags: ServicesData,
    },
    {
      description: "What industry are you in?",
      tags: ExpertiesData,
    },
    {
      description: "What's your monthly agency budget?",
      tags: CostData,
    },
  ];

  const lastStepData = [
    "What email address would you like to send quotes to?",
    "What's your company's website?",
    "What are you looking for help with?",
  ];

  const handleCloseModal = () => {
    setIsOpenGetMatchedModal(false);
    setIsOpenCross((pre) => !pre);
  };

  const handleButtonColor = (selectedItem) => {
    console.log(activeStep, "outside slecetd item ", selectedItem);
    if (activeStep === 0) {
      scrollToTop();
      setUserQuizSelectedData({
        ...userQuizSelectedData,
        services: selectedItem,
      });
    } else if (activeStep === 1) {
      scrollToTop();
      setUserQuizSelectedData({
        ...userQuizSelectedData,
        industry: selectedItem,
      });
    } else if (activeStep === 2) {
      console.log("selectedItem ", selectedItem);
      setUserQuizSelectedData({
        ...userQuizSelectedData,
        budget: selectedItem,
      });
    }
    setActiveStep(activeStep + 1);
  };

  const CustomStepIcon = ({ active, completed }) => {
    console.log(active, completed, "123");

    if (active) {
      return <img src={circle} alt="circle-icon" />;
    } else if (completed) {
      return <img src={check} alt="check-icon" />;
    } else {
      return <img src={circlelight} alt="circle-icon" />;
    }
  };

  useEffect(() => {
    if (isMatchFound) {
      onCloseLoadingModal();
      setActiveStep(3);
    } else {
      onCloseLoadingModal();
    }
  }, [isMatchFound]);

  const handleNext = () => {
    const selectedService = ServicesData.find(
      (service) => service.id === userQuizSelectedData.services
    );
    mixpanel.track(`Preferences Setup`, {
      services: selectedService?.buttonText,
      industry: userQuizSelectedData.industry,
      budget: userQuizSelectedData.budget ?? "Under $3k",
    });
    setLoadingModal(true);
    onSubmitQuizz(userQuizSelectedData);
    onCloseLoadingModal();
  };

  const onCloseLoadingModal = () => {
    setTimeout(() => {
      setLoadingModal(false);
    }, 3000);
  };

  const scrollToTop = () => {
    if (elementRef.current) {
      elementRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const get3Matches = (e) => {
    e.preventDefault();

    // Clear previous validation errors
    setValidationErrors({});

    const errors: Partial<FormState> = {};

    if (!userQuizSelectedData.email) {
      errors.email = "Please enter an email.";
    } else if (!emailRegex.test(userQuizSelectedData.email)) {
      errors.email = "Please enter a valid email address.";
    }

    if (!userQuizSelectedData.website) {
      errors.website = "Please enter a website URL.";
    } else if (!websiteRegex.test(userQuizSelectedData.website)) {
      errors.website = "Please enter a valid website URL.";
    }

    if (!userQuizSelectedData.description) {
      errors.description = "Please enter a description.";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    onSubmitQuizz(userQuizSelectedData);
  };

  useEffect(() => {
    if (!isOpen) {
      setActiveStep(0);
      setUserQuizSelectedData({
        services: null,
        industry: null,
        budget: null,
        email: "",
        website: "",
        description: "",
      });
      setValidationErrors({});
    }
  }, [isOpen]);

  const debouncedHandleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="custom-modal"
        style={{
          background: "rgba(16, 24, 40, 0.75)",
          backdropFilter: "blur(4px)",
          overflowY: "auto",
        }}
      >
        {loadingModal ? (
          <div className="lg:w-[848px] px-[24px] py-[24px]  bg-whiteColor loading-modal-container">
            {/* <span className="text-center text-gray-700 font-inter text-lg font-semibold">Sorting through the top 1%</span> */}
            <div className="text-box">
              <div
                className={`anime-box ${isMatchFound && "animate"}`}
                id="anime_box"
              >
                <div className="text text-center text-gray-700 font-inter text-lg font-semibold">
                  Sorting through the top 1%
                </div>
                <div className="text text-center text-gray-700 font-inter text-lg font-semibold">
                  Match Found!
                </div>
              </div>
            </div>
            <img src={Search} alt="Loading" />
          </div>
        ) : activeStep === 3 ? (
          <div>
            <MatchesModal
              onSubmitDetails={(data) => onSubmitDetails(data)}
              matchCount={matchCount}
              loading={loading}
              isMobile={isMobile}
            />
          </div>
        ) : (
          // <div style={isMobile ? { display: 'flex', alignItems: 'center', flexDirection: 'column', } : {}}>

          <div
            style={
              isMobile
                ? {
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  outline: "none",
                  maxHeight: "calc(100vh - 250px)",
                }
                : {}
            }
          >
            <div
              style={{
                marginBottom: isMobile ? "-58px" : "0px",
                width: isMobile ? "calc(100% - 32px)" : "100%",
              }}
            >
              <div className="relative">
                {(localStorage.getItem("getMatchedItration") || isUserLogin().status || location.pathname.includes('AgencyDetails') ) && (
                  <div
                    className="modal-header"
                    style={{
                      position: "absolute",
                      top: isMobile ? "2%" : "23.5%",
                      right: isMobile ? "4%" : "7%",
                      cursor: "pointer",
                      zIndex: isMobile ? 11 : "",
                    }}
                    onClick={handleCloseModal}
                  >
                    X
                  </div>
                )}
                {isMobile ? (
                  <div
                    style={{
                      paddingTop: "350px",
                      backgroundColor: "#3364F7",
                      borderRadius: "12px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        backgroundColor: "#3364F7",
                        position: "absolute",
                        bottom: isMobile ? "99px" : "55px",
                        zIndex: isMobile ? "-1" : "999",
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "10px",
                          fontWeight: "500",
                          color: "white",
                        }}
                      >{`${activeStep === 0
                        ? "Start here:"
                        : `Step ${activeStep + 1} of 3`
                        }`}</span>
                    </div>{" "}
                    <MobileModalHeader
                      style={{ position: "absolute", top: "-92px", zIndex: 9 }}
                    />{" "}
                  </div>
                ) : (
                  <ModalHeader />
                )}
              </div>
              {!token && !localStorage.getItem("quizCompletedOnce") && (
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    float: "right",
                    background: "transparent",
                    minWidth: "130px",
                    zIndex: 13,
                    borderRadius: "10px",
                    paddingInline: "20px",
                  }}
                >
                  {
                    <div className="flex items-center justify-end relative">
                      <button
                        ref={buttonRef}
                        className="text-[#fff] underline underline-offset-[5px] button w-[92px]  py-[10px] rounded-[8px]  text-[#475467] flex items-center justify-between text-[14px] font-montserrat font-semibold"
                        type="submit"
                        onClick={() => {
                          setDropdown(!dropdown);
                        }}
                      >
                        Agencies
                        <svg
                          sidebar-toggle-item
                          className={
                            dropdown ? "w-6 h-6 rotate-180" : "w-6 h-6"
                          }
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      {dropdown && (
                        <>
                          <div
                            className="absolute mx-auto top-14 right-0 w-52 p-2 origin-top-right rounded-[8px] bg-white border border-whiteColor py-1 card-shadow ring-black ring-opacity-5 bg-whiteColor"
                            role="menu"
                            aria-orientation="vertical"
                            ref={dropdownRef}
                            style={{ zIndex: "999" }}
                          >
                            <Link to={"/agency-login"}>
                              <button
                                className="hover:bg-[#F9FAFB] text-[14px] font-montserrat font-medium w-full flex items-center justify-start gap-x-2 p-1 rounded-[6px] text-[#344054] mb-1 py-1"
                                onClick={() => setDropdown(false)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M10 2H10.8C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.0799 14 5.2V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H10M6.66667 4.66667L10 8M10 8L6.66667 11.3333M10 8L2 8"
                                    stroke="#344054"
                                    stroke-width="1.6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Agency Login
                              </button>
                            </Link>

                            <Link
                              to={
                                `https://qjn4modo51c.pro.typeform.com/to/QOCYFX8i${utmParams.utm_source ? `?utm_source=${utmParams.utm_source}` : ''}${utmParams.utm_medium ? `&utm_medium=${utmParams.utm_medium}` : ''}${utmParams.utm_campaign ? `&utm_campaign=${utmParams.utm_campaign}` : ''}`
                              }
                              target="_blank"
                            >
                              <button
                                className="hover:bg-[#F9FAFB] text-[14px] font-montserrat font-medium w-full flex items-center justify-start gap-x-2 p-1 rounded-[6px] text-[#344054] mb-1 py-1"
                                onClick={() => setDropdown(false)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_2117_65619)">
                                    <path
                                      d="M6.00004 7.99967L7.33337 9.33301L10.3334 6.33301M11.9342 3.33201C12.0714 3.66403 12.3349 3.92794 12.6667 4.06572L13.8302 4.54766C14.1622 4.6852 14.426 4.949 14.5636 5.28105C14.7011 5.61309 14.7011 5.98617 14.5636 6.31821L14.082 7.48088C13.9444 7.81307 13.9442 8.18652 14.0824 8.51855L14.5632 9.68087C14.6313 9.84533 14.6664 10.0216 14.6665 10.1996C14.6665 10.3777 14.6315 10.554 14.5633 10.7184C14.4952 10.8829 14.3953 11.0324 14.2694 11.1582C14.1435 11.2841 13.9941 11.3839 13.8296 11.452L12.6669 11.9336C12.3349 12.0709 12.071 12.3344 11.9333 12.6662L11.4513 13.8297C11.3138 14.1617 11.05 14.4255 10.718 14.5631C10.3859 14.7006 10.0129 14.7006 9.68085 14.5631L8.51823 14.0815C8.18619 13.9443 7.81326 13.9446 7.48142 14.0823L6.31797 14.5635C5.98612 14.7007 5.61338 14.7006 5.28162 14.5632C4.94986 14.4258 4.68621 14.1623 4.54858 13.8306L4.06652 12.6667C3.92924 12.3347 3.66574 12.0708 3.33394 11.933L2.17048 11.4511C1.8386 11.3136 1.57488 11.05 1.4373 10.7181C1.29971 10.3863 1.29953 10.0134 1.43678 9.68137L1.91835 8.51871C2.05554 8.18666 2.05526 7.81371 1.91757 7.48186L1.43669 6.31753C1.36851 6.15307 1.3334 5.97679 1.33337 5.79876C1.33335 5.62073 1.3684 5.44444 1.43652 5.27996C1.50465 5.11548 1.60452 4.96604 1.73042 4.84018C1.85632 4.71431 2.00579 4.61449 2.17028 4.54641L3.33291 4.06482C3.66462 3.92766 3.92836 3.66447 4.06624 3.33305L4.54816 2.16955C4.68569 1.8375 4.94949 1.5737 5.28152 1.43616C5.61355 1.29862 5.98662 1.29862 6.31865 1.43616L7.48127 1.91775C7.81331 2.05495 8.18624 2.05467 8.51808 1.91697L9.68202 1.43691C10.014 1.29945 10.387 1.29948 10.719 1.43699C11.0509 1.5745 11.3147 1.83823 11.4522 2.17018L11.9343 3.33403L11.9342 3.33201Z"
                                      stroke="#344054"
                                      stroke-width="1.6"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2117_65619">
                                      <rect
                                        width="16"
                                        height="16"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                Verify my Agency
                              </button>
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  }
                </div>
              )}

              {!token && localStorage.getItem("quizCompletedOnce") && (
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    float: "right",
                    background: "#fff",
                    minWidth: "130px",
                    zIndex: 13,
                    borderRadius: "10px",
                    paddingInline: "20px",
                  }}
                >
                  {
                    <div className="flex items-center justify-end relative">
                      <button
                        ref={buttonRef}
                        className="button w-[92px]  py-[10px] rounded-[8px]  text-[#475467] flex items-center justify-between text-[14px] font-montserrat font-semibold"
                        type="submit"
                        onClick={() => {
                          setDropdown(!dropdown);
                        }}
                      >
                        Agencies
                        <svg
                          sidebar-toggle-item
                          className={
                            dropdown ? "w-6 h-6 rotate-180" : "w-6 h-6"
                          }
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                      {dropdown && (
                        <>
                          <div
                            className="absolute mx-auto top-14 right-0 w-52 p-2 origin-top-right rounded-[8px] bg-white border border-whiteColor py-1 card-shadow ring-black ring-opacity-5 bg-whiteColor"
                            role="menu"
                            aria-orientation="vertical"
                            ref={dropdownRef}
                            style={{ zIndex: "999" }}
                          >
                            <Link to={"/agency-login"}>
                              <button
                                className="hover:bg-[#F9FAFB] text-[14px] font-montserrat font-medium w-full flex items-center justify-start gap-x-2 p-1 rounded-[6px] text-[#344054] mb-1 py-1"
                                onClick={() => setDropdown(false)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M10 2H10.8C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.0799 14 5.2V10.8C14 11.9201 14 12.4802 13.782 12.908C13.5903 13.2843 13.2843 13.5903 12.908 13.782C12.4802 14 11.9201 14 10.8 14H10M6.66667 4.66667L10 8M10 8L6.66667 11.3333M10 8L2 8"
                                    stroke="#344054"
                                    stroke-width="1.6"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                Agency Login
                              </button>
                            </Link>

                            <Link
                              to={
                                `https://qjn4modo51c.pro.typeform.com/to/QOCYFX8i${utmParams.utm_source ? `?utm_source=${utmParams.utm_source}` : ''}${utmParams.utm_medium ? `&utm_medium=${utmParams.utm_medium}` : ''}${utmParams.utm_campaign ? `&utm_campaign=${utmParams.utm_campaign}` : ''}`
                              }
                              target="_blank"
                            >
                              <button
                                className="hover:bg-[#F9FAFB] text-[14px] font-montserrat font-medium w-full flex items-center justify-start gap-x-2 p-1 rounded-[6px] text-[#344054] mb-1 py-1"
                                onClick={() => setDropdown(false)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_2117_65619)">
                                    <path
                                      d="M6.00004 7.99967L7.33337 9.33301L10.3334 6.33301M11.9342 3.33201C12.0714 3.66403 12.3349 3.92794 12.6667 4.06572L13.8302 4.54766C14.1622 4.6852 14.426 4.949 14.5636 5.28105C14.7011 5.61309 14.7011 5.98617 14.5636 6.31821L14.082 7.48088C13.9444 7.81307 13.9442 8.18652 14.0824 8.51855L14.5632 9.68087C14.6313 9.84533 14.6664 10.0216 14.6665 10.1996C14.6665 10.3777 14.6315 10.554 14.5633 10.7184C14.4952 10.8829 14.3953 11.0324 14.2694 11.1582C14.1435 11.2841 13.9941 11.3839 13.8296 11.452L12.6669 11.9336C12.3349 12.0709 12.071 12.3344 11.9333 12.6662L11.4513 13.8297C11.3138 14.1617 11.05 14.4255 10.718 14.5631C10.3859 14.7006 10.0129 14.7006 9.68085 14.5631L8.51823 14.0815C8.18619 13.9443 7.81326 13.9446 7.48142 14.0823L6.31797 14.5635C5.98612 14.7007 5.61338 14.7006 5.28162 14.5632C4.94986 14.4258 4.68621 14.1623 4.54858 13.8306L4.06652 12.6667C3.92924 12.3347 3.66574 12.0708 3.33394 11.933L2.17048 11.4511C1.8386 11.3136 1.57488 11.05 1.4373 10.7181C1.29971 10.3863 1.29953 10.0134 1.43678 9.68137L1.91835 8.51871C2.05554 8.18666 2.05526 7.81371 1.91757 7.48186L1.43669 6.31753C1.36851 6.15307 1.3334 5.97679 1.33337 5.79876C1.33335 5.62073 1.3684 5.44444 1.43652 5.27996C1.50465 5.11548 1.60452 4.96604 1.73042 4.84018C1.85632 4.71431 2.00579 4.61449 2.17028 4.54641L3.33291 4.06482C3.66462 3.92766 3.92836 3.66447 4.06624 3.33305L4.54816 2.16955C4.68569 1.8375 4.94949 1.5737 5.28152 1.43616C5.61355 1.29862 5.98662 1.29862 6.31865 1.43616L7.48127 1.91775C7.81331 2.05495 8.18624 2.05467 8.51808 1.91697L9.68202 1.43691C10.014 1.29945 10.387 1.29948 10.719 1.43699C11.0509 1.5745 11.3147 1.83823 11.4522 2.17018L11.9343 3.33403L11.9342 3.33201Z"
                                      stroke="#344054"
                                      stroke-width="1.6"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2117_65619">
                                      <rect
                                        width="16"
                                        height="16"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                Verify my Agency
                              </button>
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  }
                </div>
              )}


            </div>
            <div
              ref={elementRef}
              className="lg:w-[848px] px-[24px] py-[24px]  bg-whiteColor get-matched-modal-container"
              style={{
                minHeight: isMobile ? "273px" : "245px",
                height: "min-content",
                position: "relative",
                overflowY: "auto",
                border: isMobile ? "8px solid #3364F7" : "",
                marginTop: isMobile ? "-25px" : "0px",
              }}
            >
              <div className="flex flex-col gap-[32px]">
                {/* <div className="relative">
								<div className={`text-center font-semibold leading-8 ${isMobile ? " text-[18px] " : " text-[24px] "} text-[#344054] font-inter`}>
									Get Matched
								</div>
								{
									isMobile &&
									<div className="text-center font-semibold leading-8 text-[14px] text-[#344054] font-inter">
										{`(60 sec, completely free)`}
									</div>
								}
								<div
									role="button"
									onClick={onClose}
									className="absolute right-[-6px] top-[-6px]"
								>
									<CrossBtnIcon />
								</div>
							</div> */}
                <div
                  style={{
                    width: "100%",
                    maxWidth: "800px",
                    alignSelf: "center",
                  }}
                >
                  {!isMobile && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <span
                        className="text-center  capitalize font-semibold text-base leading-normal font-montserrat"
                        style={{ color: "#344054" }}
                      >{`Step ${activeStep + 1} of 3`}</span>
                    </div>
                  )}
                  {/* <Stepper
									activeStep={activeStep}
									alternativeLabel
									sx={{
										width: "100%",
										"& .MuiStepConnector-line": {
											display: "none"
										},
									}}
								>
									{

										<>
											{steps.map((label, index) => (
												<Step key={label}>
													<StepLabel
														StepIconComponent={({ active, completed }) => {
															if (index === activeStep) {
																return <img src={circle} alt="circle-icon" style={{ zIndex: 2, backgroundColor: "white" }} />;
															} else if (index < activeStep) {
																return <img src={check} alt="check-icon" style={{ zIndex: 2, backgroundColor: "white" }} />;
															}
															else {
																return <img src={circlelight} alt="circle-icon" style={{ zIndex: 2, backgroundColor: "white" }} />;
															}
														}} style={{ position: "relative" }}>
														{
															!isMobile &&

															<span
																style={{
																	color: index === activeStep ? "#3364F7" : "#344054",
																	fontSize: "16px",
																	fontWeight: "600",
																	lineHeight: "20px",
																}}
															>
																{label}
															</span>
														}
														{
															index !== 0 &&
															<div style={{ height: "2px", width: "100%", background: index <= activeStep ? "#3364f7" : "#EAECF0", position: "absolute", top: "10px", left: "-55%", zIndex: 1 }}></div>
														}

													</StepLabel>
												</Step>
											))}

										</>
									}

								</Stepper> */}
                  {/* {
											isMobile &&
											<div className="w-full flex" style={{ alignItems: "center", justifyContent: "center" }}>
												<span
													style={{
														color: "#3364F7",
														fontSize: "16px",
														fontWeight: "600",
														lineHeight: "20px",
													}}
												>
													{steps[activeStep]}
												</span>
											</div>
										} */}
                </div>
              </div>
              <div
                className={`flex flex-col  items-center justify-center ${isMobile ? "pt-[1px]" : "pt-[8px]"
                  }
                ${isMobile ? "gap-[0px]" : "gap-[16px]"}
                  px-[0px] pb-[0px]`}
              >
                {/* {activeStep === 5 ? (
										<>
											{lastStepData.map((des, index) => (
												<div className="flex flex-col items-center justify-center gap-[8px]" style={{ width: "calc(100% - 32px)", maxWidth: "400px" }}>
													<p className="font-inter text-[18px] font-semibold leading-[28px] text-[#344054]" style={{ textAlign: "left", alignSelf: "flex-start" }}>
														{des}
													</p>
													<div className="flex flex-col items-start gap-[6px] self-stretch">
														{
															index !== 2
																?
																<input
																	className={`flex ${index === 2 ? "h-[100px] self-start pt-0" : "h-[40px]"
																		} w-full px-[8px] py-[12px] self-stretch rounded-[8px]`}
																	type="text"

																	name={index === 0 ? "email" : index === 1 ? "website" : "description"}
																	value={index === 0 ? userQuizSelectedData.email : index === 1 ? userQuizSelectedData.website : userQuizSelectedData.description}
																	onChange={(e) => {
																		setUserQuizSelectedData({
																			...userQuizSelectedData,
																			[e.target.name]: e.target.value
																		})

																		if (validationErrors?.email && e.target.name === "email") {
																			if (!e.target.value) {
																				setValidationErrors({ ...validationErrors, email: "Please enter an email." })
																			} else if (!emailRegex.test(e.target.value)) {
																				setValidationErrors({ ...validationErrors, email: "Please enter a valid email address." })
																			} else {
																				setValidationErrors({ ...validationErrors, email: "" })
																			}
																		} else if (validationErrors?.website && e.target.name === "website") {
																			if (!e.target.value) {
																				setValidationErrors({ ...validationErrors, website: "Please enter a website URL." })
																			} else if (!websiteRegex.test(e.target.value)) {
																				setValidationErrors({ ...validationErrors, website: "Please enter a valid website URL." })
																			} else {
																				setValidationErrors({ ...validationErrors, website: "" })
																			}
																		}

																	}}
																	style={{
																		outline: "none",
																		borderColor: validationErrors[index === 0 ? "email" : index === 1 ? "website" : "description"]
																			? "#F04438"
																			: "#D0D5DD",
																	}}
																/>
																:
																<textarea
																	className={` self-start pt-0 w-full px-[8px] py-[12px] self-stretch rounded-[8px]`}
																	style={{
																		height: "100px", maxHeight: "100px", minHeight: "100px", outline: "none",
																		borderColor: validationErrors["description"]
																			? "#F04438"
																			: "#D0D5DD",
																	}}
																	name={"description"}
																	value={userQuizSelectedData.description}
																	onChange={(e) => {
																		setUserQuizSelectedData({
																			...userQuizSelectedData,
																			[e.target.name]: e.target.value
																		})

																		if (validationErrors?.description) {
																			if (!userQuizSelectedData.description) {
																				setValidationErrors({ ...validationErrors, description: "Please enter a description." })
																			} else {
																				setValidationErrors({ ...validationErrors, description: "" })
																			}
																		}
																	}}
																/>
														}
														{validationErrors[index === 0 ? "email" : index === 1 ? "website" : "description"] && (
															<p className=" text-sm" style={{ color: "#F04438" }}>
																{validationErrors[index === 0 ? "email" : index === 1 ? "website" : "description"]}
															</p>
														)}
													</div>
												</div>
											))}
											<div className="flex pt-[24px]">
												{
													loading
														?
														<BeatLoader color="#3364F7" cssOverride={{}} loading speedMultiplier={0.5} />
														:
														<button className="flex py-[10px] px-[18px] justify-center rounded-[8px] bg-[#329BFA] text-whiteColor text-[16px] font-semibold leading-[24px]" onClick={(e) => { get3Matches(e) }}>
															View 3 Matches
														</button>
												}

											</div>
										</>
									) : */}
                {activeStep === 2 ? (
                  <>
                    <Slider
                      userQuizSelectedData={userQuizSelectedData}
                      setUserQuizSelectedData={setUserQuizSelectedData}
                      values={data[activeStep]?.tags}
                    />
                    <div className="flex items-center justify-end mt-5">
                      <button
                        className="w-[144px] bg-[#329BFA] px-[16px] py-[10px] rounded-[8px] text-[#FFFFFF] flex items-center justify-center text-[14px] font-montserrat font-semibold"
                        type="submit"
                        onClick={handleNext}
                        disabled={loading}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p
                      className={
                        isMobile
                          ? "text-center mb-4 text-[28px] font-semibold leading-[28px] text-color"
                          : "text-center text-[28px] font-semibold leading-[28px] text-color"
                      }
                    >
                      {data[activeStep].description}
                    </p>
                    <div
                      className="flex gap-x-[4px] gap-y-[8px] items-start justify-center"
                      style={{
                        width: "100%",
                        maxWidth: "632px",
                        flexWrap: "wrap",
                      }}
                    >
                      {data[activeStep]?.tags.map((item, index) => (
                        <div
                          key={index}
                          className="flex justify-center items-center gap-[8px] h-[26px] px-[12px] py-[12px] max-w-fit relative getMacthedTab"
                          style={{
                            transform: "skewX(-12deg)",
                            border: "1px solid #EAECF0",
                            borderRadius: "8px",
                            // fontStyle: "italic",
                            color: "#344054",
                          }}
                          role="button"
                          onClick={() => {
                            handleButtonColor(
                              activeStep === 0
                                ? item.id
                                : activeStep === 1
                                  ? item.buttonText
                                  : item.value
                            );
                          }}
                        >
                          <p className="flex items-center justify-between hover:cursor-pointer text-[16px] rounded-2xl  font-bold font-['Bebas Neue Pro'] uppercase tracking-tight">
                            {activeStep === 2
                              ? item.value === 1
                                ? "Under $3k"
                                : item.value === 2
                                  ? "Under $10k"
                                  : item.value === 3
                                    ? "Under $30k"
                                    : item.value === 4
                                      ? "Under $60k"
                                      : "Over $60k"
                              : item.buttonText}
                          </p>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
export default GetMatched;
