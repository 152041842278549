import { LeadType } from "../Components/PrivateRoute/types";
import Leads from "../Sections/AgencyListing/Leads";
function HotLeads() {
  return (
    <div>
      <Leads leadType={LeadType.HOT}/>
    </div>
  );
}

export default HotLeads;
