import React, { useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import Card from "../../Card/Card";
import { ReactComponent as CrossBtnIcon } from "../../../assets/Icons/x-btn.svg";
import { BeatLoader } from "react-spinners";
import AgencyMobileCard from "../../AgencyMobileCard/AgencyMobileCard";
import { gql, useMutation } from "@apollo/client";
import { INCREMENT_AGENCY_VIEWS } from "../../../Pages/AgencyList";
import { Checkbox } from "@mui/material";
import { ReactComponent as HourGlass } from "../../../assets/Icons/Checkmark.svg";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../../assets/Icons/arrow-right-icon-white.svg";
import { useMixpanel } from "react-mixpanel-browser";
import { ServicesData } from "../../../Utilities/utilities";

const INCREMENT_AGENCY_VISITS = gql`
  mutation IncrementAgencyVisit($id: Float!) {
    incrementAgencyVisits(id: $id) {
      id
      name
      total_views
    }
  }
`;
const PerfectMatchModal = ({
  agencies,
  isOpen,
  onClose,
  selectedService,
  onSubmit,
  loading,
}) => {
  const [selectedAgencies, setselectedAgencies] = useState([]);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 768 ? true : false
  );
  const [incrementAgencyViews] = useMutation(INCREMENT_AGENCY_VIEWS);
  const navigate = useNavigate();

  const mixpanel = useMixpanel();

  useEffect(() => {
    if (!isOpen) {
      setselectedAgencies([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedAgencies.length === 0) {
      setselectedAgencies(agencies);
    }
  }, [agencies]);

  const debouncedHandleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  const [incrementAgencyVisit] = useMutation(INCREMENT_AGENCY_VISITS);

  const handleIncrementVisits = async (id: any) => {
    try {
      const result = await incrementAgencyVisit({
        variables: { id: id },
      });
      console.log("Mutation Result:", result);
    } catch (error) {
      console.error("Mutation Error:", error);
    }
  };

  const agencyData = useMemo(() => {
    try {
      const userAgencyData = localStorage.getItem("user_agency");
      if (!userAgencyData) throw Error;
      return JSON.parse(userAgencyData);
    } catch (error) {
      localStorage.removeItem("user_agency");
      return null;
    }
  }, []);

  const handleIncrementViews = async (id: any, name: string, views: number) => {
    const tempId = parseFloat(id);
    if (agencyData?.agency?.id !== tempId) {
      try {
        const result = await incrementAgencyViews({
          variables: { id: tempId },
        });
        console.log("Mutation Result:", result);
      } catch (error) {
        console.error("Mutation Error:", error);
      }
    }
  };

  const handleSubmit = () => {
    mixpanel.track(`Quiz Flow Completed - Browse Matches Clicked`, {
      service: ServicesData.find((service) => service.id === selectedService)
        ?.buttonText,
    });
    navigate(`/listing/?services=${selectedService}`);
    window.location.reload();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{ backdropFilter: "blur(4px)", borderRadius: "15px" }}
    >
      <Box sx={isMobile ? mobileStyle : style} className="custom-scroll-bar">
        <div className="flex flex-col items-center">
          <HourGlass />
          <p
            className={`text-gray-900 text-center font-inter font-bold mt-3 text-[${
              isMobile ? "22px" : "28px"
            }]`}
          >
            {localStorage.getItem("getMatchedItration")
              ? `Our Team Is On It!`
              : "Your perfect matches"}
          </p>
          <span
            style={{
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "30px",
              color: "#475467",
              marginInline: `${isMobile ? "20px" : "100px"}`,
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            {
              " Your time is important to us, so our Austin, TX team is personally reviewing each of your matches to make sure they're a great fit."
            }
          </span>
          <span
            style={{
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "24px",
              color: "#475467",
              marginInline: "20px",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            {" In the meantime, review your perfect match candidates here:"}
          </span>
          <button
            className="w-[300px] bg-[#329BFA] mt-7 px-[16px] py-[16px] rounded-[8px] text-[#FFFFFF] flex items-center justify-center text-[18px] font-montserrat font-semibold"
            type="submit"
            onClick={handleSubmit}
            style={{
              cursor: "pointer",
            }}
          >
            Browse Match Candidates
            <div className="pl-2 w-[20px]">
              <ArrowRight />
            </div>
          </button>
        </div>
        {/* <div
          role="button"
          onClick={onClose}
          className="absolute right-[16px] top-[16px]"
        >
          <CrossBtnIcon />
        </div> */}
        {/* <div className="flex items-center justify-center" style={{ flexDirection: isMobile ? "column" : "row", width: "100%", gap: isMobile ? "0px" : "1.25rem", marginTop: "24px" }}>
          {
          localStorage.getItem("getMatchedItration")
        //   <Card
        //   incrementAgencyViews={() => { handleIncrementViews(agencies[0]?.id, agencies[0]?.name, agencies[0]?.total_views)}}
        //   agency={agencies[0]}          
        // />
        }
        </div> */}
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1136,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
  maxHeight: "870px",
  margin: 0,
};

const mobileStyle = {
  position: "absolute",
  top: "25%",
  maxheight: "50vh",
  width: "100vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "15px",
  padding: "24px 0px 36px 0px",
  margin: 0,
};

export default PerfectMatchModal;
