import React, { useEffect } from "react";
import AgencyDetails from "../../Pages/AgencyDetails";
// import BioComponent from "./bioComponent";

const customStyles = {
  listStyleType: 'disc', // This applies bullet points to your list items
  marginLeft: '20px', // This ensures that your list is indented
  padding: '0', // Resets any default padding
};

const Section3 = ({ pageContent }) => {

  useEffect(() => {
    // Select the container that has the dangerouslySetInnerHTML content
    const container = document.querySelector('.custom-html-content');
    if (container) {
      // Select all <a> tags within the container
      const links = container.querySelectorAll('a');
      // Set target="_blank" and rel="noopener noreferrer" on each link
      links.forEach(link => {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
      });
    }
  }, [pageContent?.bio]);

  return (
    <>
      <div className="px-[5%] xl:px-[10px] mt-10" style={{ width: '100%' }}>
        <div className="flex items-center justify-between">
          <h6 className="text-[24px] font-semibold font-montserrat">
            About {pageContent?.name}
          </h6>
          <div className="flex items-center flex-wrap gap-[8px]">
            {pageContent?.services?.map((service, index) => (
              <div
                key={index}
                className='flex justify-center items-center gap-[8px] h-[26px] pr-[9px] max-w-fit relative'
                style={{
                  backgroundColor: '#3364F7',
                  transform: 'skewX(-12deg)',
                  borderRadius: '8px',
                
                }}
              >
                <p className='text-[18px] uppercase text-[#FFF] font-babas flex items-center justify-between pl-2  whitespace-nowrap'>
                  {service?.service?.name}{' '}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div>
   
        </div>
        <div className="text-[16px] font-medium font-montserrat leading-[24px] mt-5 text-[#344054] bio-content"
          dangerouslySetInnerHTML={{ __html: pageContent?.bio }}
          style={customStyles} // Applying custom styles if needed
        >
        </div>
      </div>
    </>
  );
};

export default Section3;
