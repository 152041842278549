import React, { useContext, useState, useMemo, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/Icons/Logo.svg";
import { ReactComponent as Check } from "../../assets/Icons/check-square.svg";
import { ReactComponent as CheckCircle } from "../../assets/Icons/CheckVarify.svg";
import { ReactComponent as Logout } from "../../assets/Icons/log-out-01.svg";
import { ReactComponent as LogoutIcon } from "../../assets/Icons/log-out.svg";
import { ReactComponent as BuildingIcon } from "../../assets/Icons/building.svg";

import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Modal,
  useMediaQuery,
} from "@mui/material";
import { UserContext } from "../../Context/UserContext";
import GetMatchedFlow from "../../Components/GetMatchedFlow";
import ToastContext from "../../Context/ToastContext";

const Head: React.FC = () => {
  const isMobile = useMediaQuery("(max-width:786px)");
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const navigate = useNavigate();
  const { setIsGetMatchedBtnClicked } = useContext(UserContext);
  const [ showQuiz, setShowQuiz ] = useState(true)
  const { token, user, updateSession } = useContext(UserContext);
  const { showToast, hideToast } = useContext(ToastContext);
  const [openLogoutMenu, setOpenLogoutMenu] = useState(false);
  const [isOpenGuideModal, setIsOpenGuideModal] = useState(false);
  const location = useLocation();
  const [agencyData, setAgencyData] = useState<any>(null);

  const [stateUpdated, setStateUpdated] = useState(false);

  useEffect(() => {
    const userAgencyData = localStorage.getItem("user_agency");
    if (userAgencyData) {
      setAgencyData(JSON.parse(userAgencyData));
     } else {
      setAgencyData(null);
      localStorage.removeItem("user_agency");
	  setStateUpdated(!stateUpdated);
    }
   
    return(()=>{
      setShowQuiz(true)
    })
  }, [stateUpdated , token, showQuiz, showSideMenu]);
  useEffect(() => {
  
    if (selectedIndex === 0) {
      setShowQuiz(true);
    } else {
      setShowQuiz(false);
    }
  }, [selectedIndex]);

  function handleCLose(){
    if(selectedIndex === 0) {
    setSelectedIndex(-1)
    }
    setShowSideMenu(false)
  }

  const menus = [
    {
      label: "Get Matched",
      link: "/",
    },
    {
      label: "Agencies",
      link: "/listing",
    },
    {
      label: "Agency Login",
      link: "/agency-login",
      hide_on_login: true,
    },
    {
      label: "My Agency Portal",
      link: `agency-listing/${agencyData?.agency?.id}`,
      hide_on_logout: true,
    },
  ];

  const handlItemsOfDrawer=(index, menu)=>{
   

      setSelectedIndex(index);
      if(index === 0 ){
        setShowQuiz(true)
        navigate(`/listings`);
      }
      else {
        setShowQuiz(false)

        navigate(`${menu.link}`);
        setShowSideMenu(false);
      }

  }

  return (
    <>
      <div style={{ display: "none" }}>
        <GetMatchedFlow
          onClick={() => {}}
          isOpenGuideModal={isOpenGuideModal}
          setIsOpenGuideModal={setIsOpenGuideModal}
        />
      </div>
      <div
        className={`grid grid-cols-[auto_auto] items-center justify-between gap-1 sm:gap-0 max-w-[1216px] h-[62px] px-[5%] xl:mx-auto relative`}
        style={{
          backgroundColor: "#F9Fafb",
          borderBottom: "1px solid #EAECF0",
        }}
      >
        <div style={{ marginLeft: "0px" }} className="grid grid-cols-1">
          <Link to={"/"}>
            <Logo width={165} />
          </Link>
       
        </div>
        <img
          onClick={() =>
            { 
              setShowSideMenu(true)
              setShowQuiz(false)
            }
            }
          alt=""
          src={require("../../assets/images/hamburger.png")}
          height={20}
          width={20}
        />
      </div>
      {isMobile ? (
        <Modal open={showSideMenu} onClose={handleCLose}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Drawer
              sx={{
                width: window.outerWidth - 64,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: window.outerWidth - 64,
                  boxSizing: "border-box",
                },
              }}
              onClose={handleCLose}
              variant="persistent"
              anchor="left"
              open
            >
              <Link style={{ marginTop: 6 }} to={"/"}>
                <Logo width={165} />
              </Link>
              <List>
                {menus
                  .filter((menu) => {
                    if (agencyData) {
                      return !menu.hide_on_login;
                    } else if (!agencyData) {
                      return !menu.hide_on_logout;
                    }
                    return menu;
                  })
                  .map((menu, index) => (
                    <>
                    <ListItem
                      onClick={()=>handlItemsOfDrawer(index, menu)}
                      key={menu.label}
                      disablePadding
                      style={{width: '100%'}}
                    >
                      <>
                      
                      </>
                      <ListItemButton
                        className="w-full w-[100%] "
                        style={{ display: "flex" }}
                      
                      >
                        {index === 0 && selectedIndex === 0 && showQuiz ? (
                       (
                       <div className="w-[100%]">
                        
                       <GetMatchedFlow
                        isInMenuBar={true}
                        selectedIndex={selectedIndex === index}
                        onClick={() => {
                          
                          setShowSideMenu(false);
                          setShowQuiz(true)
                       
                        }}
                        isOpenGuideModal={isOpenGuideModal}
                        setIsOpenGuideModal={setIsOpenGuideModal}
                      />

                      </div>
                      )
                        ) : (
                          <>
                            {index === 1 ? (
                              <CheckCircle
                                height={24}
                                width={24}
                                style={{
                                  marginRight: "12px",
                                  stroke:
                                    selectedIndex === index
                                      ? "#17B26A"
                                      : "#344054",
                                }}
                                
                              />
                            ) : (
                              <BuildingIcon
                                height={24}
                                width={24}
                                onClick={handleCLose}
                                style={{
                                  marginRight: "12px",
                                  stroke:
                                    selectedIndex === index
                                      ? "#17B26A"
                                      : "#344054",
                                }}
                              />
                            )}
                            <span
                              style={{
                                fontFamily: "Inter",
                                fontSize: "16px",
                                fontWeight: "500",
                                color:
                                  selectedIndex === index
                                    ? "#17B26A"
                                    : "#344054",
                              }}
                            >
                              {menu.label}
                            </span>
                          </>
                        )}
                      </ListItemButton>
                     
                    </ListItem>
                    </>
                  ))}
              </List>
              {agencyData && (
                <div style={{ marginTop: "auto", width: "100%" }}>
                  <ListItem
                    onClick={() => {
                      showToast(
                        "You have been successfully log out",
                        "success"
                      );
                      setTimeout(() => {
                        hideToast();
                      }, 1000);
                      setOpenLogoutMenu(false);
                      localStorage.removeItem("user");
                      localStorage.removeItem("token");
                      localStorage.removeItem("user_agency");
                      // localStorage.clear();
                      updateSession(null, null);
                      setShowSideMenu(false);
                      navigate("/");
                    }}
                    disablePadding
                  >
                    <ListItemButton
                      className="w-full"
                      style={{ display: "flex" }}
                    >
                      <div
                        className="flex items-center justify-between w-full pb-4 pt-4"
                        style={{ borderTop: "1px solid #EAECF0" }}
                      >
                        <img
                          className="w-[40px] h-[40px]"
                          style={{ objectFit: "contain" }}
                          src={
                            agencyData?.agency?.logo_url
                              ? agencyData?.agency?.logo_url
                              : require("../../assets/images/Logomark.svg")
                                  .default
                          }
                          alt=""
                        />
                        <div className="flex-1 min-w-0">
                          <p className="truncate text-gray-700 font-inter text-sm font-semibold leading-5">
                            {agencyData?.agency?.name}
                          </p>
                          <p className="truncate text-gray-600 font-inter text-sm font-normal leading-5">
                            {agencyData?.agency?.email}
                          </p>
                        </div>
                        <div
                          className="h-[36px] w-[36] flex justify-start hover:bg-[#DFDFDF]"
                          style={{
                            padding: "9.5px 16px",
                            alignItems: "center",
                            gap: "6px",
                            cursor: "pointer",
                          }}
                        >
                          <LogoutIcon />
                        </div>
                      </div>
                    </ListItemButton>
                  </ListItem>
                </div>
              )}
            </Drawer>
            <img
              onClick={handleCLose}
              alt=""
              src={require("../../assets/images/crossWhite.png")}
              height={40}
              width={40}
            />
          </div>
        </Modal>
      ) : null}
      {!isMobile ? (
        <div className="border-t mb-8 max-w-[1216px] border-[#EAECF0] mx-[5%] xl:mx-auto "></div>
      ) : null}
    </>
  );
};

export default Head;
