import Header from '../../Sections/Header/Head'
import { Outlet } from 'react-router-dom'

const LayoutWithHeaderAndOutlet = () => {
  return (
    <>
      <Header/>
      <Outlet/>
    </>
  )
}

export default LayoutWithHeaderAndOutlet