
function ImportReviews() {
  return (
    <div>
      ImportReviews
    </div>
  )
}

export default ImportReviews
