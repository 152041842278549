import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  // PaymentElement,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import "../common/CheckoutForm"
import { BeatLoader } from 'react-spinners';
import { useParams } from 'react-router-dom';
import { AgencyDetailsInterface } from '../Interface';
import { GET_AGENCY } from '../Pages/AgencyListing';
import { StripeKeys } from '../Utilities/utilities';

const CREATE_SUBSCRIPTION_MUTATION = gql`
  mutation createSubscription($data: CreateSubscriptionInput!) {
    createSubscription(data: $data) {
      id
      agency_id
      status
    }
  }
`;

const CheckoutForm = ({ onClose, agencyId }): any => {
  const stripe = useStripe();
  const elements = useElements();
  const { id } = useParams();

  const [createSubscription] = useMutation(CREATE_SUBSCRIPTION_MUTATION);

  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [isProcessing, setisProcessing] = useState(false)
  const [getAgencies, { loading, data, error },] = useLazyQuery(GET_AGENCY, {
    variables: { id: parseInt(`${id}`) },
  });

  const validateForm = () => {
    let isValid = true;
    if (!name.trim()) {
      setNameError('Name on card is required.');
      isValid = false;
    } else {
      setNameError('');
    }
    return isValid;
  };

  const handleSubmit = async (event) => {
    setisProcessing(true)
    event.preventDefault();

    if (!validateForm() || !stripe || !elements) {
      setisProcessing(false)
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      setErrorMessage('Card details are incomplete.');
      setisProcessing(false)
      return;
    }

    const paymentMethodResult = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: { name },
    });

    if (paymentMethodResult.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(paymentMethodResult.error.message);
      setErrorMessage(paymentMethodResult.error.message);
      setisProcessing(false)
      return;
    }

    console.log("paymentMethodResult ", paymentMethodResult);

    try{
    const { data, errors } = await createSubscription({
      variables: {
        data: {
          agency_id: agencyId,
          name: name,
          pricing_id: StripeKeys.price_id,
          payment_method_id: paymentMethodResult.paymentMethod?.id
        }
      }
    });

    if (errors) {
      setErrorMessage("Payment failed. Please try again");
      setisProcessing(false)
      return;
    }

    if (data) {
      setisProcessing(false)
      let _agency = JSON.parse(localStorage.getItem("user_agency") || "")
      let _agencyData = _agency.agency
      localStorage.setItem("user_agency", JSON.stringify({ ..._agency, agency: { ..._agencyData, status: "verified" } }));
      (window.location as any).reload(true);
      // onClose()
    }
  }
  catch(e){
    setErrorMessage("Payment failed. Please try again");
    setisProcessing(false)
    return;
  }

    // if (paymentMethodResult.error) {
    //   // Show error to your customer (for example, payment details incomplete)
    //   console.log(paymentMethodResult.error.message);
    //   setErrorMessage(paymentMethodResult.error.message);
    // } else {
    //   // The paymentMethod has been successfully created.
    //   // You can now use it for the payment confirmation step.
    // }
  };



  return (
    <>
      <form className='stripe-form-section'>
        {/* <PaymentElement /> */}
        <div className="form-group">
          <label htmlFor="cardNumber">Name on card</label>
          <input placeholder='Enter name on card' onChange={(e) => { setName(e.target.value) }} type="text" id="cardNumber" />
        </div>
        <div className="form-group">
          <label htmlFor="cardNumber">Card number</label>
          <div className='input'>
            <CardNumberElement />
          </div>
        </div>

        <div style={{ width: "100%", display: "flex", gap: "16px" }}>


          <div className="form-group">
            <label htmlFor="cardNumber">Expiration date</label>
            <div className='input'>

              <CardExpiryElement />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="cardNumber">CVV</label>
            <div className='input'>
              <CardCvcElement />
            </div>
          </div>

        </div>


        <div className='text-[12px]  font-inter' style={{ color: "red" }}>{nameError ? nameError : errorMessage ? errorMessage : ""}</div>
      </form>
      <div className="form-actions w-[100%]" style={{ display: "flex", alignSelf: "flex-end", gap: "24px" }}>

        <button onClick={onClose} className="w-[50%] cancel-button secondaryBtn" style={{ textAlign: "center", alignItems: "center", justifyContent: "center" }}>
          <span className='text-[16px]'>
            Cancel
          </span>
        </button>

        <button onClick={handleSubmit} disabled={!stripe || !elements} className="w-[50%] primaryBtn" style={{ textAlign: "center", alignItems: "center", justifyContent: "center" }}>
          {
            isProcessing
              ?
              <BeatLoader color="#3364F7" cssOverride={{}} loading speedMultiplier={0.5} />
              :
              <span className='text-[16px]'>
                Get started
              </span>
          }

        </button>
      </div>
    </>
  );
};


const StripeForm = ({ onClose, agencyId }) => {

  const stripePromise = loadStripe(StripeKeys.public_key);

  // const options = {
  //   mode: "payment",
  //   amount: 1099,
  //   currency: 'usd',
  //   // Fully customizable with appearance API.
  //   appearance: {
  //     /*...*/
  //   },
  // };
  return (
    <Elements
      stripe={stripePromise}
    // options={{
    //   mode: "payment",
    //   amount: 29100,
    //   currency: 'usd',
    //   appearance: {
    //   },
    // }}
    >
      <CheckoutForm onClose={onClose} agencyId={agencyId} />
    </Elements>
  );
}


export default StripeForm;