import React from "react";

interface LoaderProps {
    stroke?: string;
    customStyle?: React.CSSProperties;
}

const Loader: React.FC<LoaderProps> = ({ stroke, customStyle }) => (
    <svg
        className={`animate-spin h-6 w-6 mx-auto ${stroke}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        style={customStyle}
    >
        <circle cx="12" cy="12" r="10" strokeOpacity="0.3" />
        <path
            d="M12 2a10 10 0 0 1 10 10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default Loader;
