import React, { useState } from 'react';
import './UpgradeStripeModal.css'; // Ensure the CSS file is correctly linked
import StripeForm from '../../../common/CheckoutForm';
import { ReactComponent as Check } from "../../../assets/Icons/check_icon.svg"
import { ReactComponent as FeaturedIcon } from "../../../assets/Icons/featured_icon.svg"


const UpgradeModal = ({ open, onClose, agencyId }) => {

    const [isMobile] = useState(() => {
        return window.innerWidth <= 600;
    })

    return (
        <div className="modal-backdrop" style={{ display: open ? "flex" : "none" }}>
            <div className="modal-container">
                {
                    !isMobile &&
                    <div className="left-section">
                        <FeaturedIcon />
                        <h2 className="modal-title text-[18px] font-semibold  font-inter">Upgrade Easily</h2>
                        <p className="plan-price text-[48px] font-semibold  font-inter">$291/mo</p>
                        <div className='flex w-[100%]' style={{ justifyContent: "flex-start", alignItems: "center", flexDirection: "column", gap: "4px" }}>
                            <p className="plan-name text-[20px] font-semibold  font-inter" style={{ lineHeight: "24px" }}>"Verified Agency" Plan</p>
                            <p className="plan-description text-[16px]  font-inter" style={{ lineHeight: "24px" }}>Cancel Anytime</p>
                        </div>
                        <ul className="plan-benefits">
                            <div className='flex gap-[12px]'>
                                <Check />
                                <p>Unlock historic Profile Visits instantly</p>
                            </div>
                            <div className='flex gap-[12px]'>
                                <Check />
                                <li>Higher rankings in all searches & lists</li>
                            </div>
                            <div className='flex gap-[12px]'>
                                <Check />
                                <li>Unlimited Hot Leads</li>
                            </div>
                            <div className='flex gap-[12px]'>
                                <Check />
                                <li>Unlimited Profile Visits</li>
                            </div>

                            <div className='flex gap-[12px]'>
                                <Check style={{ opacity: "0" }} />
                                <li>So much more...</li>
                            </div>
                        </ul>
                    </div>
                }
                <div className="right-section" style={isMobile ? { width: '100%' } : {}}>
                    <h3 className="form-title text-[20px] font-semibold  font-inter" style={{ lineHeight: "30px" }}>Last Step</h3>
                    <StripeForm onClose={onClose} agencyId={agencyId} />
                </div>
            </div>
        </div>
    );
};

export default UpgradeModal;
