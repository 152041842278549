/* eslint-disable react/jsx-no-target-blank */
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { usePagination } from '../../Context/PaginationContext';
import Table from '../../Components/Table/Table';
import Pagination from '../../Components/Pagination/Pagination';
import { useParams } from 'react-router-dom';
import { ReactComponent as LockIcon } from './../../assets/Icons/lock_icon.svg'
import StripeForm from '../../common/CheckoutForm';
import UpgradeModal from '../../Components/Modal/UpgradeStripeModal/UpgradeStripeModal';

const GET_LEADS = gql`
mutation getWarmLeads($data: WarmLeadsListInput!) {
    getWarmLeads(data: $data) {
        total
        subscription {
            id
            status
        }
    	data {
            id
            AgencyId
            name
            website
            domain
            description
            industries
            employee_range
            founded_year
            location
            user_location
            geo
            profiles
            emails
            phones
            created_at
            extra
            revenue
            technologies
            technology_categories
            monthly_visitors
            business_type
        }
    }
  }
`

function isJSON(str) {
    try {
        JSON.stringify(JSON.parse(str));
        return true;
    } catch (e) {
        return false;
    }
}

const ProfileVists = ({ pageContent }) => {

    const warmRef = useRef(null);
    const { currentPage, itemsPerPage, setItemPerPage } = usePagination('warmLeadsPagination');

    const [userAgency, setUserAgency] = useState<any>(null);
    const params = useParams()
    const [leads, setLeads] = useState([])
    const [subscription, setSubscription] = useState<any>(null);
    const [openModal, setopenModal] = useState(false)

    const [leadsCount, setleadsCount] = useState(-1)
    const table_header = [
        { name: "created_at", title: "Date", width: "130px" },
        { name: "name", title: "Company Name", width: "125px" },
        { name: "domain", title: "Links ", width: "250px", },
        { name: "location", title: "Visitor Location", width: "180px", },
        { name: "Company_HQ", title: "Company HQ", width: "180px" },
        { name: "emails", title: "Emails", width: "180px" },
        // { name: "profiles", title: "Profiles", width: "180px" },
        { name: "description", title: "Description", width: '300px',  },
    ]

    const Data = [
        { created_at: "Lorem Ipsum", email: "Lorem Ipsum", contact: "Lorem Ipsum", website: "Lorem Ipsum", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." },

        { created_at: "Lorem Ipsum", email: "Lorem Ipsum", contact: "Lorem Ipsum", website: "Lorem Ipsum", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." },

        { created_at: "Lorem Ipsum", email: "Lorem Ipsum", contact: "Lorem Ipsum", website: "Lorem Ipsum", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." },

        { created_at: "Lorem Ipsum", email: "Lorem Ipsum", contact: "Lorem Ipsum", website: "Lorem Ipsum", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." },

        { created_at: "Lorem Ipsum", email: "Lorem Ipsum", contact: "Lorem Ipsum", website: "Lorem Ipsum", description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." },

    ]

    const [getLeads] = useMutation(GET_LEADS);

    const parseLeadProfile = (profile) => {
        try {
            return JSON.parse(profile);
        } catch (error) {
            return null;
        }
    }

    function getLinkedinUrl(profiles) {
        let linkedinData = profiles?.linkedin;

        if (typeof linkedinData === 'object') {
            // If linkedinData is an object, return the 'url' key or an empty string
            return linkedinData.url || '';
        } else if (typeof linkedinData === 'string') {
            // If linkedinData is a string, return it directly
            return linkedinData;
        } else {
            // If neither, return an empty string
            return '';
        }
    }
    function getFacebookUrl(profiles) {
        let linkedinData = profiles?.facebook;

        if (typeof linkedinData === 'object') {
            // If linkedinData is an object, return the 'url' key or an empty string
            return linkedinData.url || '';
        } else if (typeof linkedinData === 'string') {
            // If linkedinData is a string, return it directly
            return linkedinData;
        } else {
            // If neither, return an empty string
            return '';
        }
    }
    function getTwiter(profiles) {
        let linkedinData = profiles?.twitter;

        if (typeof linkedinData === 'object') {
            // If linkedinData is an object, return the 'url' key or an empty string
            return linkedinData.url || '';
        } else if (typeof linkedinData === 'string') {
            // If linkedinData is a string, return it directly
            return linkedinData;
        } else {
            // If neither, return an empty string
            return '';
        }
    }

    function completeUrl(url) {
        // Check if the URL already starts with http:// or https://
        if (!url.match(/^http[s]?:\/\//)) {
            url = 'https://' + url; // Add https:// if missing
        }

        // Check if the URL already has www. after the protocol
        if (!url.match(/^http[s]?:\/\/www\./)) {
            url = url.replace(/^http[s]?:\/\//, 'https://www.'); // Add www. after the protocol
        }

        return url;
    }

    const getLeadsFunc = async (page) => {
        const result = await getLeads({
            variables: {
                data: {
                    page: page,
                    pageSize: 5,
                    agency_id: parseInt(`${params.id}`)
                }
            }
        })
        setLeads(result?.data?.getWarmLeads?.data?.map((lead, _) => {

            const profiles = parseLeadProfile(lead.profiles);
            console.log({ profiles });

            const _location = isJSON(lead.location) ? JSON.parse(lead.location) : {}
            const company_location = isJSON(lead.extra) ? JSON.parse(lead.extra) : {}
            let templocation = isJSON(lead.location) === false ? lead.location : `Address: ${_location.address || " - "}\nCity: ${_location.city || "  -  "}\nCountry: ${_location.country || " - "}\nPostcode: ${_location?.postcode || "-"}`
            let tempcomplocation = isJSON(lead.extra) === false ? lead.extra : `Address: ${company_location.address || " - "}\nCity: ${company_location.city || " - "}\nCountry: ${company_location.country || " - "}\nPostcode: ${company_location?.postcode || "-"}`
            return ({
                ...lead,
                domain: <div className='w-[100%] flex' style={{ flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", }}>
                    {
                        lead.domain &&
                        <span >Domain: <a style={{ textDecoration: "underline", color: "blue" }} href={`${completeUrl(`${lead.domain}`)}`} target="_blank"  >{`${lead?.domain}`}</a></span>
                    }
                    {
                        getLinkedinUrl(profiles) &&
                        <span style={{maxWidth:"150px"}}>Linkedin: <a style={{ textDecoration: "underline", color: "blue" }} href={`${completeUrl(`${getLinkedinUrl(profiles)}`)}`} target="_blank"  >{`${getLinkedinUrl(profiles)}`}</a></span>
                    }
                    {
                        getFacebookUrl(profiles) &&
                        <span style={{maxWidth:"150px"}} >facebook: <a style={{ textDecoration: "underline", color: "blue" }} href={`${completeUrl(`${getFacebookUrl(profiles)}`)}`} target="_blank"  >{`${getFacebookUrl(profiles)}`}</a></span>
                    }
                    {
                        getTwiter(profiles) &&
                        <span style={{maxWidth:"150px"}}>Twitter: <a style={{ textDecoration: "underline", color: "blue" }} href={`${completeUrl(`${getTwiter(profiles)}`)}`} target="_blank"  >{`${getTwiter(profiles)}`}</a></span>
                    }

                </div>,
                // phones: lead.phones.length > 0 && lead.phones.map((phone, p) => { if (phone && phone !== "undefined") { return (`${p > 0 ? ", " : ""}${phone}`) } }),
                emails: lead.emails.length > 0 && lead.emails.map((email, p) => { return (`${p > 0 ? ", " : ""}${email}`) }),
                location: templocation,
                Company_HQ: tempcomplocation,
                created_at: parseInt(`${lead.created_at}`)
            })
        }) || [{ domain: "powerhouse.so", description: "123 321" }])
        setleadsCount(result?.data?.getWarmLeads?.total);
        setSubscription(result?.data?.getWarmLeads?.subscription);
    }

    const userAgencyData = localStorage.getItem("user_agency");

    useEffect(() => {
        try {

            if (
                userAgencyData !== undefined &&
                userAgencyData !== "undefined" &&
                JSON.parse(userAgencyData || '')
            ) {
                let agency = JSON.parse(userAgencyData || '');
                setUserAgency(agency?.agency || null);
                console.log(agency?.agency, "userAgencyData");
                if (agency?.agency.status === "unverified" || agency?.agency.status === "hidden") {
                    return
                }
                getLeadsFunc(currentPage);
            }
        } catch (error) { }
    }, [userAgencyData, currentPage]);

    useEffect(() => {
        if (warmRef.current && currentPage > 1) {
          (warmRef.current as HTMLElement).scrollIntoView({ behavior: "smooth" });
        }
      }, [currentPage]);

    return (
        <>
            {
                <div ref={warmRef} id='snapshot' style={{ display: `${userAgency?.status === "unverified" ? 'none' : 'flex'}`, flexDirection: "column", width: '100%', paddingTop: '12px', minHeight: '300px', marginTop: `${leads.length <= 1 ? '20px' : '0px'}` }} className='px-[5%] xl:px-[10px]'>
                    <h6 className='text-[24px] font-semibold  font-inter tracking-[0.44px] mb-2' style={{ marginBottom: "24px" }}>
                        Profile Visits
                    </h6>
                    <>
                        {
                            <UpgradeModal
                                open={openModal}
                                onClose={() => { setopenModal(false) }}
                                agencyId={userAgency?.id}
                            />
                        }
                        <div style={{ position: 'relative' }}>
                            {
                                (userAgency?.status !== "verified" && userAgency?.status === "free") &&

                                <div
                                    style={{
                                        width: '362px',
                                        height: '260px',
                                        backgroundColor: 'white',
                                        boxShadow: '#101828 0 0 6px -4px',
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        zIndex: 1,
                                        margin: 'auto',
                                        borderRadius: '12px',
                                        padding: '24px',
                                        marginTop: `${leads.length <= 1 ? '0px' : 'auto'}`
                                    }}
                                >
                                    <div>
                                        <LockIcon />
                                        <h4
                                            className='mt-[16px] text-[18px] font-[600]'
                                        >
                                            Upgrade your plan to unlock leads
                                        </h4>
                                        <span
                                            className='mt-[16px] text-[14px] font-[400]'
                                        >
                                            You are in a free plan, you can upgrade your plan to unlock potential leads.
                                        </span>
                                    </div>
                                    <button
                                        onClick={() => { setopenModal(true) }}
                                        style={{ border: 'none', color: '#FFF' }}
                                        className='rounded-[8px] bg-[#329BFA] mt-[32px] w-[100%] px-[18px] py-[10px] text-[16px] font-[600]'
                                    >
                                        Upgrade
                                    </button>
                                </div>
                            }
                            <Table
                                emptyMessage={"No profile visits found."}
                                header={table_header}
                                rows={(userAgency?.status !== "verified") ? leadsCount === 0 ? Data : leads : leads}
                                blur={userAgency?.status !== "verified"} />
                            {(leadsCount) > 5 && (
                                <Pagination
                                    dataCount={leadsCount} type={"for-leads"}
                                    blur={userAgency?.status !== "verified"}
                                    paginationKey={"warmLeadsPagination"}
                                />
                            )}
                        </div>
                    </>

                </div>
            }
        </>
    );
};

export default ProfileVists;
