const urlParts = window.location.href.split("/");
export const lastItem = urlParts[urlParts.length - 1];
export const isIdPresent= !isNaN(parseInt(lastItem))
export const redirectUrl =
  "https://www.agencyreviews.io/AgencyDetails/" + lastItem;
export function isUserLogin() {
  try {
    const sessionDetails = localStorage.getItem("user");
    if (sessionDetails) {
      const user = JSON.parse(sessionDetails);
      if (user && user.email) {
        try {
          if (typeof lastItem != undefined && !isNaN(parseInt(lastItem))) {
            if (user["default/agency_id"] == lastItem)
              return { status: true, url: redirectUrl };
            else {
              return { status: false, url: redirectUrl };
            }
          } else {
            return { status: true, url: redirectUrl };
          }
        } catch (ex) {
          return { status: true, url: redirectUrl };
        }
      } else {
        return { status: false, url: redirectUrl };
      }
    } else {
      return { status: false, url: redirectUrl };
    }
  } catch (ex) {
    return { status: false, url: redirectUrl };
  }
}
