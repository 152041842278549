import React, { Component, useMemo, useState } from "react";
import { ReactComponent as CheckMark } from "../../assets/Icons/blueCheck.svg";
import { ReactComponent as HourGlass } from "../../assets/Icons/TimerBlue.svg";
import { ReactComponent as WebsiteIcon } from "../../assets/Icons/globe.svg";
import { ReactComponent as PhoneIcon } from "../../assets/Icons/phone.svg";
import { ReactComponent as MailIcon } from "../../assets/Icons/mailBox.svg";
import { ReactComponent as ArrowRight } from "../../assets/Icons/arrow-narrow-right.svg";
import { ReactComponent as EyeIcon } from "../../assets/Icons/eye1.svg";
import { useMixpanel } from "react-mixpanel-browser";
import { BeatLoader } from "react-spinners";
import { isModifier } from "typescript";

interface FormState {
  email: string;
  website: string;
  description: string;
  phoneNumber: string;
}

interface LastStepData {
  title: string;
  header: string;
  type: string;
  name: string;
  email: string;
  website: string;
  description: string;
  icon: React.ReactNode;
}

export default function MatchesModal({
  onSubmitDetails,
  loading,
  isMobile,
  matchCount,
}) {
  const mixpanel = useMixpanel();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const websiteRegex =
    /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}([/?].*)?$/;

  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');


  const [activeStep, setActiveStep] = useState(0);
  const [lastStepData, setLastStepData] = useState<LastStepData>({
    title: "",
    header: "",
    type: "",
    name: "",
    email: "",
    website: "",
    description: "",
    icon: null,
  });
  const [validationErrors, setValidationErrors] = useState<Partial<FormState>>(
    {}
  );

  const handleContinueButton = (e: any) => {
    e.preventDefault();

    setValidationErrors({});

    const errors: Partial<FormState> = {};

    if (activeStep === 1 && lastStepData.description.length < 10) {
      errors.description = "Please enter a description at least 10 characters.";
    }

    if (activeStep === 0 && !lastStepData.website) {
      errors.website = "Please enter a website URL.";
    } else if (activeStep === 0 && !websiteRegex.test(lastStepData.website)) {
      errors.website = "Please enter a valid website URL.";
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    if (activeStep === 2) {
      mixpanel.track(`Question 1 Submitted - Email`, {
        questionTitle: lastStepData.title,
        questionType: lastStepData.name,
        email: lastStepData.email,
      });
    } else if (activeStep === 0) {
      mixpanel.track(`Question 2 Submitted - Website`, {
        questionTitle: lastStepData.title,
        questionType: lastStepData.name,
        website: lastStepData.website,
      });
    }
    setActiveStep(activeStep + 1);
  };

  function validatePhoneNumber(phoneNumber) {
    // Regular expression to match various phone number formats
    const phoneRegex = /^\+?(\d{1,3})?[-. (]?(\d{1,4})[-. )]?[-. ]?(\d{1,4})[-. ]?(\d{1,9})$/;

    // Test the phone number against the regex
    return (phoneRegex.test(phoneNumber) && phoneNumber.replace(/[^+\d]/g, '').length <= 16);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setValidationErrors({});
    console.log({ validationErrors });
    if (activeStep === 2 && !lastStepData.email && !phoneNumber) {
      console.log("1");
      
      setValidationErrors({
        ...validationErrors,
        email: "Please enter an email.",
        phoneNumber: "Please enter a phone number",
      });
      return;
    } else if (activeStep === 2 && !lastStepData.email) {
      console.log("2");

      setValidationErrors({
        ...validationErrors,
        email: "Please enter an email.",
      });
      return;
    } else if (activeStep === 2 && !emailRegex.test(lastStepData.email) && !phoneNumber) {
      console.log("3");
      setValidationErrors({
        ...validationErrors,
        email: "Please enter a valid email address.",
        phoneNumber: "Please enter a phone number",
      });
      return;
    }else if (activeStep === 2 && !emailRegex.test(lastStepData.email) ) {
      console.log("3");
      setValidationErrors({
        ...validationErrors,
        email: "Please enter a valid email address.",
      });
      return;
    }  else if (activeStep === 2 && !phoneNumber.length) {
      console.log("4");
      setValidationErrors({
        ...validationErrors,
        phoneNumber: "Please enter a phone number",
      });
      return;
    } else if (activeStep === 2 && !validatePhoneNumber(phoneNumber)) {
      console.log("5");
      setValidationErrors({
        ...validationErrors,
        phoneNumber: "Please enter a valid phone number",
      });
      return;
    }
    mixpanel.track(`Question 3 Submitted - Description`, {
      questionTitle: lastStepData.title,
      questionType: lastStepData.name,
      description: lastStepData.description,
    });
    localStorage.setItem("quizCompletedOnce", "true");
    localStorage.setItem("dntOpenModal", "true");
    onSubmitDetails({
      email: lastStepData.email,
      website: lastStepData.website,
      description: lastStepData.description,
      phoneNumber: phoneNumber
    });
  };

  useMemo(() => {
    if (activeStep === 0) {

      setLastStepData({
        ...lastStepData,
        title: "What’s your company’s website?",
        type: "website",
        name: "website",
        header: `${matchCount} ${matchCount === 1 ? "Match" : "Matches"
          } Secured`,
        icon: <CheckMark />,

      });
    } else if (activeStep === 1) {
      setLastStepData({
        ...lastStepData,
        title: "Tell us a bit about what you need help with?",
        type: "text",
        name: "description",
        header: "Almost there!",
        icon: <HourGlass />,
      });
    }
    else if (activeStep === 2) {
      setLastStepData({
        ...lastStepData,
        title: "Confirmed: We will only use your phone number for internal purposes. Agencies will not get your phone number.",
        type: "email",
        name: "email",
        header: "Last Step!",
        icon: <HourGlass />,
      });
    }
  }, [activeStep]);

  return (
    <div
      className="max-w-md mx-4 bg-whiteColor rounded-lg shadow-md"
      style={{ minWidth: isMobile ? "328px" : "480px", minHeight: "300px" }}
    >
      <div className="flex flex-col rounded-lg items-center space-y-4">
        <div className="flex flex-col rounded-t-lg items-center bg-[#329BFA] w-full space-y-2 py-4" >
          <div>{lastStepData?.icon}</div>
          <h2 className="text-lg font-medium text-[white]">
            {lastStepData?.header}
          </h2>
          {
            activeStep == 2 &&
            <p
              className="text-center font-light text-sm leading-6 text-[#fff]"
            >
              <span className="font-medium text-[#fff]">Confirmed:</span> We will only use your phone number for internal purposes. Agencies will not get your phone number.
            </p>
          }
        </div>

        {activeStep !== 2 && <p
          className="text-base text-center px-2 font-semibold leading-6 font-inter text-[#344054]"
        >
          {lastStepData?.title}
        </p>
        }
        <div className="flex flex-col items-center w-full px-8" >
          {activeStep === 1 ? (
            <textarea
              className="w-full !pl-4 md:text-base text-sm !pr-4 !py-2"
              placeholder="Tell us more... "
              style={{ outline: "none" }}
              minLength={10}
              name={"description"}
              value={lastStepData?.description}
              onChange={(e) => {
                setLastStepData({
                  ...lastStepData,
                  [e.target.name]: e.target.value,
                });

                if (validationErrors?.description) {
                  if (
                    !lastStepData.description ||
                    lastStepData.description === ""
                  ) {
                    setValidationErrors({
                      ...validationErrors,
                      description:
                        "Please enter a description at least 20 characters.",
                    });
                  } else {
                    setValidationErrors({ ...validationErrors, description: "" });
                  }
                }
              }}
            />
          ) : activeStep === 0 ? (
            <div className="relative w-full">
              <WebsiteIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
              <input
                placeholder="Please enter your website"
                className="w-full  md:text-base text-sm !pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                type={lastStepData.type}
                name={lastStepData?.name}
                onChange={(e) => {
                  setLastStepData({
                    ...lastStepData,
                    [e.target.name]: e.target.value.toLowerCase(),
                  });
                  if (
                    validationErrors?.website &&
                    e.target.name === "website"
                  ) {
                    if (!e.target.value) {
                      setValidationErrors({
                        ...validationErrors,
                        website: "Please enter a website URL.",
                      });
                    } else if (!websiteRegex.test(e.target.value.toLowerCase())) {
                      setValidationErrors({
                        ...validationErrors,
                        website: "Please enter a valid website URL.",
                      });
                    } else {
                      setValidationErrors({ ...validationErrors, website: "" });
                    }
                  }
                }}
                value={
                  activeStep === 0 ? lastStepData.website : ""
                }
              />
            </div>
          ) :
            <div className="space-y-4" >
              <p
                className="text-base text-center font-bold leading-6 font-inter text-[#344054]"
              >
                {'What email address would you like to send quotes to?'}
              </p>
              <div className="relative w-full">
                <MailIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <input
                  placeholder="Please enter your email address"
                  className="w-full md:text-base text-sm !pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  type={lastStepData.type}
                  name={lastStepData?.name}
                  onChange={(e) => {
                    setLastStepData({
                      ...lastStepData,
                      [e.target.name]: e.target.value.toLowerCase(),
                    });

                    if (validationErrors?.email && e.target.name === "email") {
                      if (!e.target.value) {
                        setValidationErrors({
                          ...validationErrors,
                          email: "Please enter an email.",
                        });
                      } else if (!emailRegex.test(e.target.value.toLowerCase())) {
                        setValidationErrors({
                          ...validationErrors,
                          email: "Please enter a valid email address.",
                        });
                      } else {
                        setValidationErrors({ ...validationErrors, email: "" });
                      }
                    } else if (
                      validationErrors?.website &&
                      e.target.name === "website"
                    ) {
                      if (!e.target.value) {
                        setValidationErrors({
                          ...validationErrors,
                          website: "Please enter a website URL.",
                        });
                      } else if (!websiteRegex.test(e.target.value.toLowerCase())) {
                        setValidationErrors({
                          ...validationErrors,
                          website: "Please enter a valid website URL.",
                        });
                      } else {
                        setValidationErrors({ ...validationErrors, website: "" });
                      }
                    }
                  }}
                  value={
                    activeStep === 2 ? lastStepData.email : ""
                  }
                />
              </div>
              {validationErrors.email && (
                <p className=" text-sm" style={{ color: "#F04438" }}>
                  {
                    validationErrors.email
                  }
                </p>
              )}
              <p
                className="text-base text-center font-bold leading-6 font-inter text-[#344054]"
              >
                {'What’s your phone number?'}
              </p>
              <div className="relative w-full">
                <PhoneIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 pointer-events-none" />
                <input
                  placeholder="Please enter your phone number"
                  className="w-full md:text-base text-sm !pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  type={'contact'}
                  name={'Phone Number'}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    if (validatePhoneNumber(e.target.value)) {
                      setValidationErrors({ ...validationErrors, phoneNumber: "" });
                    }
                  }}
                  value={phoneNumber}
                />
              </div>
              {validationErrors.phoneNumber && (
                <p className=" text-sm" style={{ color: "#F04438" }}>
                  {
                    validationErrors.phoneNumber
                  }
                </p>
              )}
            </div>


          }
          {validationErrors[
            activeStep === 0
              ? "website"
              : "description"
          ] && (
              <div className="w-full mt-2" >
                <p className="text-sm" style={{ color: "#F04438" }}>
                  {
                    validationErrors[
                    activeStep === 0
                      ? "website"
                      : "description"
                    ]
                  }
                </p>
              </div>

            )}
          {loading ? (
            <BeatLoader
              color="#3364F7"
              cssOverride={{}}
              loading
              speedMultiplier={0.5}
            />
          ) : (
            <div className="flex items-center justify-end mt-6 mb-6">
              {activeStep === 2 ? (
                <button
                  className="w-[244px] bg-[#329BFA] px-[16px] py-[10px] rounded-[8px] text-[#FFFFFF] flex items-center justify-center text-[14px] font-montserrat font-semibold"
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Reveal Perfect Matches
                  <div className="pl-1">
                    <EyeIcon />
                  </div>
                </button>
              ) : (
                <button
                  className="w-[144px] bg-[#329BFA] px-[16px] py-[10px] rounded-[8px] text-[#FFFFFF] flex items-center justify-center text-[14px] font-montserrat font-semibold"
                  type="submit"
                  onClick={(e) => handleContinueButton(e)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Continue
                  <div className="pl-1">
                    <ArrowRight />
                  </div>
                </button>
              )}
            </div>
          )}
          {activeStep === 2 && (
            <div className="mb-4 text-center" >
              <span
                className="text-center font-inter text-xs font-normal leading-custom "
                style={{ color: "#98A2B3" }}
              >
                *By submitting this form, you consent to our{" "}
                <span
                  style={{ color: "#1667f2", cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      "https://docs.google.com/document/d/1ChZYpEscrJ722CeX1WGxTce1T7V3LUZO3URxB13vLKo/edit?pli=1#heading=h.bzyf4yq6caxk",
                      "_blank" // <- This is what makes it open in a new window.
                    );
                  }}
                >
                  terms of service
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
