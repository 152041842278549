import React, { useState, useEffect } from 'react';

const HTMLRenderer = () => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchHTMLContent = async () => {
      try {
        const response = await fetch('/widgets.html');
        const content = await response.text();
        console.log('HTML Content:', content);
        setHtmlContent(content);
      } catch (error) {
        console.error('Error fetching HTML content:', error);
      }
    };
    fetchHTMLContent();
  }, []);

  const formatTotalRatings = (totalRatings) => {
    return totalRatings.toFixed(1); // Format to one decimal place
  };

  const renderWidget = () => {
    return htmlContent.replace(
      '{total_ratings}',
      formatTotalRatings(3.125) // Replace {total_ratings} with formatted value
    );
  };

  return (
    <div dangerouslySetInnerHTML={{ __html: renderWidget() }} />
  );
};

export default HTMLRenderer;
