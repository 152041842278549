import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SitemapRedirect = () => {
  const navigate = useNavigate();

  const getbackendUrl = () => {
    let backendUrl = "";
    const baseUrl = window.location.href;
    if (baseUrl.includes("staging")) {
      backendUrl = `https://agencyreviews-api-staging.powerhouse.so/sitemap/sitemap_index.xml`;
    } else if (baseUrl.includes("dev")) {
      backendUrl = `https://agencyreviews-api-dev.powerhouse.so/sitemap/sitemap_index.xml`;
    } else if (baseUrl.includes("localhost")) {
      backendUrl = `https://agencyreviews-api-dev.powerhouse.so/sitemap/sitemap_index.xml`;
    } else {
      backendUrl = `https://api.agencyreviews.io/sitemap/sitemap_index.xml`;
    }
    return backendUrl;
  };

  useEffect(() => {
    window.location.replace(getbackendUrl());
  }, [navigate]);

  return null; // Optionally, you can add a loading indicator if desired
};

export default SitemapRedirect;
