import { ReactNode } from "react";

export interface PrivateRouteProps {
  children: ReactNode;
}

export enum LeadType {
  ARRCHIVED = "ARCHIVED",
  WARM = "WARM",
  HOT ="HOT",
}

export type NodeEnv = 'development' | 'staging' | 'main';


