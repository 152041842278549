import React from 'react';
import Section1 from '../Sections/AgencyLogin/Section1';

const AgencyLogin: React.FC = () => {
	
	return (
		<>
			<Section1 />
		</>
	);
};

export default AgencyLogin;
